import crypto from "crypto";
import jsrsasign from 'jsrsasign'

let rsa_public_key = "b3764d7f25497d340ae81953af7a898e1c1ea320489b17868906c537b33b6446f24ce902227db22540bfd80da256b4fc53b385ea7a4233a4e1e02d0be19aac8fac1e23830de13968bb089f767533716c03032172f8e32e2f105e22ff12ecc0f0ffae68b70f70fa6994dafd56e011178b339791b762413a29d1960b4fbe133c50bc8c3bf11bd985309440d1b9e3770dcc291f91e5388fe95dee06b2de9ca0ad35c0b89ba9e2ac282276bb09f477f3d0ffc5384f245b900b4d17eb3d16129d0666f02668459ea987531ab8b00c184ffd198d74114ccf34691ba63152812c3f4bbd415fef1770f9b4a4a178ae89eed96fd99c73ff750356a5905ae268a8cdff0657";
let rsa_public_exponent = "10001";

// rsa加密
// 不可加密数字，否则解密为空
function rsa(text) {
    let pub = jsrsasign.KEYUTIL.getKey({ n: rsa_public_key, e: rsa_public_exponent })
    let enc = jsrsasign.KJUR.crypto.Cipher.encrypt(text, pub)
    return `rsa.${enc}`
}

// md5加密
function md5(str) {
    let md5 = crypto.createHash("md5");
    md5.update(str);
    let md5Result = md5.digest("hex");
    return md5Result;
}

export {rsa, md5}